<template>
  <div>
    <div class="d-flex align-center">
      <ChartOptions
        :types="displayDataType.optionTypes"
        :datePickerType="
          (['meter', 'solar'].includes(kind) || null) &&
          (tabIndex === 0
            ? 'perHour'
            : tabIndex === 1
            ? 'perDay'
            : tabIndex === 2
            ? 'perMonth'
            : null)
        "
        :schoolMeters="filterSchoolMeters"
        v-model="searchCondition"
        :noTarget="displayDataType.noTarget || isClassroomPage"
      />
      <v-btn
        class="ml-4 px-8"
        color="primary"
        @click="getMeterHistory"
        :disabled="!searchable"
        >查詢</v-btn
      >

      <v-menu offset-y rounded="0" width="80" max-width="80">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-4 px-8 mr-4"
            color="primary"
            outlined
            v-bind="attrs"
            v-on="on"
            :loading="loadingExport"
            :disabled="!searchable"
            >下載報表</v-btn
          >
        </template>
        <v-list color="accent" dark class="pa-0" width="80" max-width="80">
          <v-list-item @click="exportFile('csv')">
            <v-list-item-title>CSV</v-list-item-title>
          </v-list-item>
          <v-list-item @click="exportFile('json')">
            <v-list-item-title>JSON</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <MeterChart
      :loading="loadingMeterHistory"
      :meterHistory="meterHistory"
      :filterDataTypes="displayDataType"
      :searchCondition="searchCondition"
      :executeSearch="executeSearch"
      :searchTypeIsSchoolMeter="searchType === 'schoolMeter'"
      :kindIsAc="kind === 'ac'"
    />
  </div>
</template>

<script>
import ChartOptions from '@/components/ChartOptions'
import MeterChart from '@/components/Analysis/MeterChart'
import History from '@/api/ems/report/History'
import Meter from '@/api/ems/Meter'
import { mapGetters } from 'vuex'

export default {
  components: {
    MeterChart,
    ChartOptions
  },
  props: {
    displayDataType: {
      type: Object,
      default: () => {}
    },
    tabIndex: {
      type: Number,
      required: true
    },
    kind: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      searchCondition: {
        list: [],
        timestamp: []
      },
      meterHistory: [],
      loadingMeterHistory: false,
      loadingExport: false,
      executeSearch: false
    }
  },
  computed: {
    ...mapGetters({
      schoolMeters: 'analysis/schoolMeters',
      chartColors: 'config/chartColors',
      pageData: 'page/pageData',
      classroomMeters: 'analysis/classroomMeters'
    }),
    isClassroomPage() {
      return this.$route.path.includes('class')
    },
    classroomId() {
      return this.$route.path.includes('class') ? this.$route.params.tagId : ''
    },
    searchType() {
      return this.searchCondition.list[0]?.data?.type
    },
    meters() {
      let Ids = this.searchCondition.list.map((d, index) => {
        return {
          index,
          id: d.value,
          name: d.name,
          type: d.selectors[0].key
        }
      })
      if (this.searchType === 'schoolMeter') {
        Ids = []
        this.searchCondition.list.forEach((d, index) => {
          Ids.push({
            index,
            id: d.value,
            name: `${d.name}_${d.data.meter.name}`
          })
        })
      }
      if (this.searchType === 'classMeter') {
        Ids = []
        this.searchCondition.list.forEach((d, index) => {
          d.deviceList.forEach((device) => {
            if (device.type === 'CLASSMETER')
              Ids.push({
                index,
                id: device.id,
                name: `${d.name}_${device.name}`
              })
          })
        })
      }
      return Ids.filter((d) => d.id)
    },
    filterSchoolMeters() {
      switch (this.kind) {
        case 'meter':
          return this.schoolMeters.filter(
            (m) => m.type !== 'SOLARMETER' && m.type !== 'VIRTUALPVMETER'
          )
        case 'solar':
          return this.schoolMeters.filter(
            (m) => m.type === 'SOLARMETER' || m.type === 'VIRTUALPVMETER'
          )
        default:
          return []
      }
    },
    classroomPageSearchIds() {
      if (this.kind === 'meter' || this.kind === 'solar')
        return this.classroomMeters
      if (
        this.kind === 'ac' ||
        (this.kind === 'env' && this.tabIndex === 0) ||
        this.kind === 'air'
      )
        return [
          {
            id: this.classroomId,
            name: this.pageData.displayName
          }
        ]
    },
    searchable() {
      if (this.displayDataType.noTarget || this.isClassroomPage)
        return (
          this.searchCondition.timestamp[0] &&
          (this.tabIndex === 0 && ['meter', 'solar'].includes(this.kind)
            ? true
            : this.searchCondition.timestamp[1])
        )
      else
        return (
          this.searchCondition.timestamp[0] &&
          (this.tabIndex === 0 && ['meter', 'solar'].includes(this.kind)
            ? true
            : this.searchCondition.timestamp[1]) &&
          (this.isClassroomPage
            ? this.classroomPageSearchIds.length > 0
            : this.meters.length > 0)
        )
    },
    schoolId() {
      return this.isClassroomPage
        ? this.pageData.schoolId
        : this.$route.params.tagId
    }
  },
  methods: {
    async getClimateHistory() {
      this.loadingMeterHistory = true
      this.meterHistory = []
      try {
        const { data: meterHistory } = await History.climatologyHistory(
          {
            start: this.searchCondition.timestamp[0],
            end: this.searchCondition.timestamp[1]
          },
          this.schoolId
        )
        this.meterHistory.push({
          color: this.chartColors[0],
          name: this.pageData.name || '學校',
          data: meterHistory
        })
      } catch (error) {
        console.error(error)
        this.meterHistory.push({
          color: this.chartColors[0],
          name: this.pageData.name || '學校',
          data: []
        })
      }
      this.loadingMeterHistory = false
    },
    async getMeterHistory() {
      this.executeSearch = true
      if (this.kind === 'env' && this.tabIndex !== 0) this.getClimateHistory()
      else {
        if (this.isClassroomPage) {
          if (
            !this.classroomPageSearchIds ||
            this.classroomPageSearchIds.length <= 0
          )
            return
        } else {
          if (!this.meters || this.meters.length <= 0) return
        }
        this.loadingMeterHistory = true
        this.meterHistory = []
        let meters = this.isClassroomPage
          ? this.classroomPageSearchIds
          : this.meters
        for (let index = 0; index < meters.length; index++) {
          const theMeter = meters[index]
          try {
            // 小時、日、月用電量
            if (
              (this.kind === 'meter' || this.kind === 'solar') &&
              (this.tabIndex === 0 ||
                this.tabIndex === 1 ||
                this.tabIndex === 2) &&
              theMeter.type !== 'group'
            ) {
              let reportName = ''
              switch (this.tabIndex) {
                case 0:
                  reportName = 'daily'
                  break
                case 1:
                  reportName = 'monthly'
                  break
                case 2:
                  reportName = 'yearly'
                  break
              }
              const { data: meterHistory } = await Meter.statistic(
                theMeter.id,
                reportName,
                {
                  isSchoolMeter: this.searchType === 'schoolMeter',
                  date: this.searchCondition.timestamp[0]
                }
              )
              this.meterHistory.push({
                color: this.chartColors[index],
                name: theMeter.name,
                data: meterHistory.energy.map(({ timestamp, value }) => {
                  return {
                    timestamp,
                    consumption: value
                  }
                })
              })
            } else {
              let exeFunction = Function
              if (theMeter.type === 'group')
                exeFunction = History.groupMeterHistory
              else {
                if (this.kind === 'meter' || this.kind === 'solar')
                  exeFunction = History.meterHistory
                if (this.kind === 'ac') exeFunction = History.classAcHistory
                if (this.kind === 'env' && this.tabIndex === 0)
                  exeFunction = History.classAcMergedHistory
                if (this.kind === 'air')
                  exeFunction = History.ventilationSystemHistory
              }
              const { data: meterHistory } = await exeFunction(theMeter.id, {
                start: this.searchCondition.timestamp[0],
                end: this.searchCondition.timestamp[1]
              })

              if (this.kind === 'ac')
                meterHistory.forEach(({ name, data, ...rest }, hIndex) => {
                  this.meterHistory.push({
                    color: this.chartColors[hIndex],
                    name: `${hIndex + 1} ${name}`,
                    data: data,
                    dataInfo: { ...rest }
                  })
                })
              else
                this.meterHistory.push({
                  color: this.chartColors[index],
                  name: theMeter.name,
                  data: meterHistory
                })
            }
          } catch (error) {
            console.error(error)
            this.meterHistory.push({
              color: this.chartColors[index],
              name: theMeter.name,
              data: []
            })
          } finally {
            this.loadingMeterHistory = false
          }
        }
      }
    },
    async getClimateHistoryExport(fileType) {
      this.loadingExport = true
      try {
        const response = await History.climatologyHistoryExport(
          {
            start: this.searchCondition.timestamp[0],
            end: this.searchCondition.timestamp[1]
          },
          fileType,
          this.schoolId
        )
        this.downloadFile(response, fileType, this.pageData.name || '學校')
      } catch (error) {
        console.error(error)
      }
      this.loadingExport = false
    },
    async exportFile(fileType) {
      if (this.kind === 'env' && this.tabIndex !== 0)
        this.getClimateHistoryExport(fileType)
      else {
        if (this.isClassroomPage) {
          if (
            !this.classroomPageSearchIds ||
            this.classroomPageSearchIds.length <= 0
          )
            return
        } else {
          if (!this.meters || this.meters.length <= 0) return
        }
        this.loadingExport = true
        let meters = this.isClassroomPage
          ? this.classroomPageSearchIds
          : this.meters
        for (let index = 0; index < meters.length; index++) {
          const theMeter = meters[index]
          try {
            let response = {}
            if (
              (this.kind === 'meter' || this.kind === 'solar') &&
              (this.tabIndex === 0 ||
                this.tabIndex === 1 ||
                this.tabIndex === 2) &&
              theMeter.type !== 'group'
            ) {
              let reportName = ''
              switch (this.tabIndex) {
                case 0:
                  reportName = 'daily'
                  break
                case 1:
                  reportName = 'monthly'
                  break
                case 2:
                  reportName = 'yearly'
                  break
              }
              response = await Meter.schoolStatisticExport(
                theMeter.id,
                reportName,
                fileType,
                {
                  isSchoolMeter: this.searchType === 'schoolMeter',
                  date: this.searchCondition.timestamp[0]
                }
              )
            } else {
              let exeFunction = Function
              if (
                (this.kind === 'meter' || this.kind === 'solar') &&
                (this.tabIndex === 1 || this.tabIndex === 2)
              ) {
              }
              if (theMeter.type === 'group')
                exeFunction = History.groupMeterHistoryExport
              else {
                if (this.kind === 'meter' || this.kind === 'solar')
                  exeFunction = History.meterHistoryExport
                if (this.kind === 'ac')
                  exeFunction = History.classAcHistoryExport
                if (this.kind === 'env' && this.tabIndex === 0)
                  exeFunction = History.classAcMergedHistoryExport
                if (this.kind === 'air')
                  exeFunction = History.ventilationSystemHistoryExport
              }
              response = await exeFunction(
                {
                  start: this.searchCondition.timestamp[0],
                  end: this.searchCondition.timestamp[1]
                },
                fileType,
                theMeter.id
              )
            }
            this.downloadFile(response, fileType, theMeter.name)
          } catch (error) {
            console.error(error)
          }
        }
        this.loadingExport = false
      }
    },
    downloadFile(response, fileType, meterName = '') {
      if (fileType === 'csv') {
        const url = window.URL.createObjectURL(
          new Blob([(response.data ? '\ufeff' : '') + response.data], {
            type: 'text/csv;charset=utf-8;'
          })
        )
        const link = document.createElement('a')
        link.href = url
        let fileName = this.getResponseFilename(fileType, meterName)
        link.setAttribute('download', fileName) // or any other extension
        document.body.appendChild(link)
        link.click()
      } else if (fileType === 'json') {
        var dataStr =
          'data:text/json;charset=utf-8,' +
          encodeURIComponent(JSON.stringify(response.data))
        var downloadAnchorNode = document.createElement('a')
        downloadAnchorNode.setAttribute('href', dataStr)
        let fileName = this.getResponseFilename(fileType, meterName)
        downloadAnchorNode.setAttribute('download', fileName)
        document.body.appendChild(downloadAnchorNode) // required for firefox
        downloadAnchorNode.click()
        downloadAnchorNode.remove()
      }
    },
    getResponseFilename(fileType, meterName) {
      return `${meterName}_${this.searchCondition.timestamp[0]}_${this.searchCondition.timestamp[1]}.${fileType}`
    }
  }
}
</script>
